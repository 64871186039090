$sw-bg: #FFFFFF;
$sw-txt-color: #475467;
$phonics-font-family: 'Inter';
$phonics-font-style: "normal";
$pre-reader-active-color:#fbf1cf;
$emerging-reader-active-color:#e2eedb;
$reading-fluency-active-color:#f8e5d7;

/*
    New questions::
    prereaders LB_ALP needs wordbook
    500 parasol,four friends are not published so it is not included.



    meeting:
    discussion: hover should underline icon
    heading title too tight >>OK
    8 rows per page****************************
    unit page should scroll
    conditions for gaming
    Coming Soon (lesson plan)
    word book,storybook will have see more label.
    pop should come from design
    design url:
    https://www.figma.com/file/U8R8CARTQ1PZ703Rqg7jbk/Phonics-Tab-%2F-Library-page?node-id=101%3A75109&t=CIPxSgrrTXeoHzj5-1

        https://www.figma.com/file/U8R8CARTQ1PZ703Rqg7jbk/Phonics-Tab-%2F-Library-page?node-id=101%3A75109&t=IODykn06QriVMnfc-0
        1. heading text size, family should be set during discussion, lesson plan hover is remaining. (URL: fontstyle, size)

        2. card styling remaining
            a. underline book title text on card image hover needs additional scripting overhead.
            book title hover: text,image links

        3. text cutting functionality is remaining
            2 words and see more

        4. top nav bar: it has a blue background, that is shared with rails, angular and dashboard itself.
            Turning white needs to be overviewed across all of them. Should we continue with blue?

        5. searching: book title, unit title or unit number?
            search priority:
                one letter should search from letter book only (first priority in search).
                beginning two characters in the begining should search from space seprated phonics. eg: ww => w w
                Multi characters (more than 3) should show other books.
            grey color, border bottom should not go all the way

        6. data questions
            Spreadhsheet status: finalized
            pre
            https://docs.google.com/spreadsheets/d/1UCgB7fuv75q_ErpEC2tOVi9FzL2zx-cw/edit#gid=584278385
            emer
            https://docs.google.com/spreadsheets/d/1FmyGkUqNH3QX82QV3HsdKx8IPbczq-hU/edit#gid=584278385
            fluency
            https://docs.google.com/spreadsheets/d/1M9IKYRTAFWb2DNSHPMN6rzC2Y-tznL69/edit#gid=584278385

            a. phonics column "PHONICS BOOK TITLE (ABC)" is not clear? is it exactly same as unit title?
                is card body title and spreadsheet columns "PHONICS BOOK TITLE (ABC)" are same?
                will these columns ever overlap?

                1. should be 1 keyword set per booktypes?
                eg: column E and M,PHONICS KEY WORDS (6), STORY "KEY WORDS" PHONICS (words - listen) and PHONICS
                I also see DOLCH column. Are they going in keywords set.

                Label should be phonics.
                columns after storybooks comment should be ignored.

            b. is unit title same as book title found in cardbody?  (Probably No)
            c. pagination and search is remaining.



*/
.phonics-container {
    background: $sw-bg;
    color: $sw-txt-color;
    font-family: "Inter";
    font-style: normal;

}

.phonics-heading {
    font-family: "Quicksand";
    font-style: normal;

    span {
        font-weight: 600;
        font-size: xx-large;
        line-height: 44px;
        letter-spacing: -0.02em;
        color: #101828;        
    }

    p {

        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: $sw-txt-color;
        font-family: 'Inter';
        font-style: normal;
        margin-top: 16px;
    }
}


.sw-tab {
    margin-top: 60px;
    .sw-tab-heading-text {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
    }

    .nav-tabs .nav-item.show .nav-link,
    .nav-tabs .nav-link.active,
    .nav-link:hover {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: #F5F8FF;
        flex: none;
        order: 0;
        flex-grow: 0;
        border: transparent;
        border-bottom: 1px solid #2F40B7;
    }

    .nav-link.active {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600 !important;
        font-size: 16px;
        line-height: 24px;
        color: #2F40B7 !important;
    }

    .nav-link.active.active-pre-readers{
        background-color: $pre-reader-active-color !important;
    }
    .nav-link.active.active-emerging-readers{
        background-color: $emerging-reader-active-color !important;
    }
    .nav-link.active.active-reading-fluency{
        background-color: $reading-fluency-active-color !important;
    }

    .nav-link {
        font-family: 'Inter';
        font-style: normal !important;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #667085;
    }
}

.phonics-card-row-wrapper{
    background: #F9FAFB;
    border-radius: 8px;    
    // margin-top: 20px;
    overflow:"scroll";
    height: "80vh";
}

.phonics-card {
    .book-cover>img:hover {
        border: 1px solid #6c757d36;
        box-shadow: 0px 1px 3px rgb(16 24 40 / 10%), 1px 2px 8px 7px rgb(16 24 40 / 10%);
        border-radius: 8px;
    }

    .book-cover {
        padding: 12px 0px 15px 15px;
        width: 134px;
        img {
            height: 160px !important;
            width: 104px !important;
            box-sizing: border-box;
            border: 1px solid #EAECF0;
            border-bottom-left-radius: unset !important;
            border-top-left-radius: unset !important;
            border-radius: 8px !important;
            flex: none;
            order: 0;
            flex-grow: 0;

        }
    }

    .card {
        height: 185px;
        background: #FFFFFF;
        border: 1px solid #EAECF0;
        box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
        border-radius: 8px;
    }
    .card-body {
        padding-left: unset;
        margin-left: 10px;
        a { color:black; }
    }
    .badge {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 2px 8px;
        width: 94px;
        height: 22px;
        background: #F8F9FC !important;
        border-radius: 16px;
        flex: none;
        order: 0;
        flex-grow: 0;
    }

    span {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        text-align: center;
        color: #363F72;
    }

    .card-title {
        width: 189.33px;
        height: 56px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
        color: #101828;
        flex: none;
        order: 1;
        align-self: stretch;
        flex-grow: 0;

    }
    .card-title:hover{
        text-decoration: underline;
    }

    .keywords {
        // display: flex;
        height: auto;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        height: 55px;
        text-align: left;
        color: #667085;
        flex: none;
        order: 2;
        align-self: stretch;
        flex-grow: 0;
        a { color: #667085; }
    }

    .action-rows:hover {
        a { color: #6079FF; }        
        img { cursor: pointer; }
        border-bottom: 2px solid #6079FF;
        padding-bottom: 2px;
    }

    .action-rows {
        margin-right: 10px;
        display: inline;
        width: 102px;
        height: 20px;
        color: #2F40B7;
        flex: none;
        order: 1;
        flex-grow: 0;

        a {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 20px;
            color: #2F40B7;
            text-decoration: none;
            margin-left: 3px;
        }
    }
}

.unit-bar {
    .heading {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        color: #667085;
    }

    .lesson-plan {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #2F40B7;
    }

    margin-bottom: 10px;
    margin-top: 25px;
}

.phonics-search-text {
    padding-left: 3px !important;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    background: #FFFFFF;
    border: 1px solid #D0D5DD;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    outline: none !important;
    border-left: none !important;
}

.search-bar>input:focus {
    box-shadow: none;
    border: 1px solid #D0D5DD;
    border-left: none;
}